.header-container {
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-container {
  outline: auto;
  outline-width: 10px;
  overflow: hidden;
  width: 65vw;
}